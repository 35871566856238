import { LocalStoreKey } from "common/constants";

export const setObjectLocalData = (key: LocalStoreKey, data: object) => {
    window.localStorage.setItem(key, JSON.stringify(data));
};

export const getObjectLocalData = <T>(key: LocalStoreKey): T | undefined => {
    const jsonData = window.localStorage.getItem(key);
    if (jsonData) {
        try {
            return JSON.parse(jsonData);
        } catch {
            return undefined;
        }
    }
    return undefined;
};

export const setStringLocalData = (key: LocalStoreKey, value: string) => {
    window.localStorage.setItem(key, value);
};

export const getStringLocalData = (key: LocalStoreKey): string | null => {
    return window.localStorage.getItem(key);
};

export const removeLocalItem = (key: LocalStoreKey) => {
    window.localStorage.removeItem(key);
};
