import React from 'react' //lazy
import foooter1 from 'assets/images/button/withdrawfooter.png'
import foooter2 from 'assets/images/button/dispositfooter.png'
import foooter3 from 'assets/images/button/Messagefooter.png'
import logo from 'assets/images/login/logo.png'
import { useHistory } from 'react-router-dom'
import { UrlInternal } from 'common/constants/endpoints'
import { Layout } from 'antd'
// import { ArrowUpOutlined } from "@ant-design/icons";

// import { UrlInternal } from "common/constants/endpoints";

import * as S from './style'
import { useTranslation } from 'react-i18next'

const { Footer } = Layout

interface Props {
  onClick?: any
  className?: any
}


const FooterPage = (props: Props) => {
  const { onClick, className } = props
  const history = useHistory()
  const handleClick = (link: any) => {
    history.push(link)
  }
  const {t} = useTranslation()

  const data1 = [
    t('home.aristocrat'),
    t('home.novometic'),
    t('home.greenTube'),
    t('home.nyxNextgen'),
    t('home.netent'),
    'EGT',
    'Igrosoft',
    'APLSGAMES',
    'megaJack',
    'UNICUM',
    'BABOCHKI'
  ]

  const data = [
    {
      image: foooter1,
      link: UrlInternal.PAYMENT,
      title: t('home.WithdrawalRequest')
    },
    {
      image: foooter2,
      link: UrlInternal.DEPOSIT,
      title: t('home.DepositRequest')
    },
    {
      image: foooter3,
      link: UrlInternal.MESSAGE,
      title: t('home.messageBox')
    }
  ]
  return (
    <S.Footers>
      <div className='left'>
        <p className='left-title'>{t('home.partnershipAffiliationInquiry')}</p>
        <div className='left-box'>
          {data &&
            data.map((item: any, index: number) => {
              return (
                <button
                  className='left-button'
                  key={index}
                  onClick={() => handleClick(item.link)}
                >
                  <img src={item.image} alt='avatar' />
                  <div className='text'>{item.title}</div>
                </button>
              )
            })}
        </div>
      </div>
      <div className='middle'>
        <img alt='logo' src={logo} />
        Copyright {new Date().getFullYear()} All Rights Reserved.
      </div>
      <div className='right'>
        <p className='right-title'>QUICK SERVICE</p>
        <div className='right-box'>
          {data1.map((i, index: number) => {
            return <span key={index}>{i}</span>
          })}
        </div>
      </div>
    </S.Footers>
  )
}

export default FooterPage
