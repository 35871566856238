import React, { type RefObject, createRef, useEffect, useRef } from 'react'
import AuthLayout from './AuthLayout'
import { AuthWrapper } from '../style'
import { Form, InputRef } from 'antd'
import CustomButton from 'components/CustomButton'
import { InputItem } from 'components/commonCustom'
import Captcha from './capcha'
import { loginApi, meApi } from 'apis/auth'
import { saveLocalStorage } from 'utils/localStorage'
import { useDispatch, useSelector } from 'react-redux'
import types from 'store/actions/types'
import { useTranslation } from 'react-i18next'

type Refs = {
  id: RefObject<InputRef>
  pass: RefObject<InputRef>
  myCaptcha: RefObject<InputRef>
}
const LoginModal = () => {
  const captcha = useRef('')
  const dispatch = useDispatch()
  const popupStates = useSelector((state: any) => state.waitting)
  const block = useRef(false)
  const refs = useRef<Refs>({
    id: createRef(),
    pass: createRef(),
    myCaptcha: createRef()
  })
  const { t } = useTranslation()
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  const onKeyDown = (e: any) => {
    if (block.current) return
    if (e.code !== 'Enter') return
    handleSubmit()
  }

  const handleSubmit = async () => {
    const { id, pass } = refs.current
    block.current = true
    if (!id.current?.input?.value) return alert('아이디는 비워두면 안 됩니다')
    if (!pass.current?.input?.value)
      return alert('비밀번호는 비워두면 안 됩니다')

    try {
      dispatch({ type: 'WAITTING' })
      const res: any = await loginApi({
        mb_id: id.current?.input?.value,
        mb_password: pass.current?.input?.value
      })
      if (res.result) {
        saveLocalStorage({
          name: 'User',
          data: res?.access_token
        })
        const resMe: any = await meApi()
        if (resMe.result === false) {
          alert(resMe.message)
          dispatch({ type: 'STOP_WAITTING' })
        } else {
          dispatch({ type: 'STOP_WAITTING' })
          dispatch({
            type: types.GET_DATA_USER,
            dataUser: resMe
          })
        }
        dispatch({ type: types.CLOSE_MODAL })
        dispatch({ type: 'STOP_WAITTING' })
      } else {
        alert(res?.error.message)
        dispatch({ type: 'STOP_WAITTING' })
      }
    } catch (error: any) {
      if (error.response) {
        alert(error.response.data.message)
      }
    } finally {
      dispatch({ type: 'STOP_WAITTING' })
      block.current = false
    }
  }

  return (
    <AuthLayout>
      <AuthWrapper>
        <div className='title'>{t('auth.logIn')}</div>
        <div className='form'>
          <div className='form-item'>
            <label>{t('auth.id')}*</label>
            <InputItem ref={refs.current.id} placeholder={`${t('auth.id')}*`} />
          </div>
          <div className='form-item'>
            <label>{t('auth.password')}*</label>
            <InputItem
              ref={refs.current.pass}
              type='password'
              placeholder={`${t('auth.password')}*`}
            />
          </div>
          <div className='btn-gr'>
            <CustomButton
              disabled={popupStates.isWaitting}
              onClick={handleSubmit}
            >
              {t('auth.logIn')}
            </CustomButton>
          </div>
        </div>
        {/* <h6>이미지 클릭시 새로운 이미지 나옴</h6> */}
      </AuthWrapper>
    </AuthLayout>
  )
}

export default LoginModal
