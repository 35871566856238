import React from 'react'
import AuthLayout from './AuthLayout'
import { AuthWrapper } from '../style'
import { Form } from 'antd'
import { InputItem, RoundedButton } from 'components/commonCustom'
import { useDispatch, useSelector } from 'react-redux'
import CustomButton from 'components/CustomButton'
import { UrlInternal } from 'common/constants/endpoints'
import { useHistory } from 'react-router-dom'
import types from 'store/actions/types'
import { createCustomerCenter } from 'apis'
import { useTranslation } from 'react-i18next'

const Qna = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { dataUser } = useSelector((state: any) => state.user)
  const popupStates = useSelector((state: any) => state.waitting)
  let history = useHistory()
  const dispatch = useDispatch()
  const handleSubmit = async (values: any) => {
    dispatch({ type: 'WAITTING' })
    // console.log(title, note, betno);
    try {
      const res: any = await createCustomerCenter({
        board: 'board09',
        board_html: '1',
        qString: 'board=board01',
        board_subject: values.title,
        board_content: values.note,
        board_field9: ''
      })
      console.log(res);
      
      if (res.result) {
        // console.log(res);
        history.push(
          `${UrlInternal.CUSTOMER_CENTER_DETAIL_ROUTER}${res?.data.insertedId}`
        )
        dispatch({ type: types.CLOSE_MODAL })
        dispatch({ type: 'STOP_WAITTING' })
        alert(res.message)
      } else {
        dispatch({ type: 'STOP_WAITTING' })
        alert(res.error.message)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleCLick = () => {
    history.push(UrlInternal.CUSTOMER_CENTER)
    dispatch({ type: types.CLOSE_MODAL })
  }
  return (
    <AuthLayout>
      <AuthWrapper className='qna'>
        <div className='title'>{t('ask.11Inquiry')}</div>
        <Form
          name='myForm'
          form={form}
          initialValues={{ remember: true }}
          className='qna'
          onFinish={handleSubmit}
        >
          <div className='form-item'>
            <label>{t('ask.title')}*</label>
            <Form.Item name='title'>
              <InputItem required placeholder={t('ask.pleaseEnterTheTitle')} />
            </Form.Item>
          </div>
          <div className='form-item'>
            <label>{t('ask.writer')}*</label>
            <span>{dataUser.mb_name}</span>
          </div>
          <div className='form-item column-mobile'>
            <label style={{ alignSelf: 'flex-start' }}>
              {t('ask.detail')}*
            </label>
            <Form.Item name='note'>
              <InputItem.TextArea required placeholder={t('ask.contents')} />
            </Form.Item>
          </div>
          <div className='btn-gr'>
            <CustomButton type='button' onClick={handleCLick}>
              {t('ask.list')}
            </CustomButton>
            <CustomButton disabled={popupStates.isWaitting} type='submit'>
              {t('ask.writting')}
            </CustomButton>
          </div>
        </Form>
      </AuthWrapper>
    </AuthLayout>
  )
}

export default Qna
