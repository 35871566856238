import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from 'translation/language/en_lang.json'
import ko from 'translation/language/ko_lang.json'
import vi from 'translation/language/vi_lang.json'
import jp from 'translation/language/jp.json'
import { getStringLocalData, setStringLocalData } from 'utils/localStore';

const getLangParams = ()=>{
    const langList = ['ko', 'vi', 'en', 'jp']
    const url =  window.location
    const splitUrl = getStringLocalData('lang') || url.pathname.split('/').filter(Boolean)[0]
    const foundUrl = langList.find((e)=> e.includes(splitUrl))
    return foundUrl
}

const resources = {
  en: {
    translation: en
  },
  vi:{
    translation: vi
  },
  ko:{
    translation: ko
  },
  jp:{
    translation: jp
  }
};

i18n
  .use(initReactI18next)
  // .use(new LanguageDetector(null, { lookupLocalStorage: "lang" }))
  .init({
    resources,
    lng: `${getLangParams() || 'ko'}`,
    debug: true,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  }).then(() =>{
    const lang = getStringLocalData('lang')
    if(!lang){
      setStringLocalData('lang', 'ko')
    }
  })

export default i18n;

