import { createGlobalStyle } from 'styled-components'
import { theme } from 'styled-tools'
import {
  RobotoThin,
  SCDream1,
  SCDream2,
  SCDream3,
  SCDream4,
  SCDream5,
  SCDream6,
  SCDream7,
  SCDream8,
  SCDream9,
  NotoSansKrBlack,
  NotoSansKrBold,
  NotoSansKrLight,
  NotoSansKrMedium,
  NotoSansKrRegular,
  NotoSansKrThin
} from 'assets/fonts/font'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import Close from 'assets/icons/close.svg'

const GlobalStyles = createGlobalStyle`

// Roboto
@font-face {
  font-family: 'Roboto-Thin';
  src: local('Roboto-Thin'), url(${RobotoThin}) format('opentype');
}
@font-face {
  font-family: 'SCDream1';
  src: local('SCDream1'), url(${SCDream1}) format('opentype');
}
@font-face {
  font-family: 'SCDream2';
  src: local('SCDream2'), url(${SCDream2}) format('opentype');
}
@font-face {
  font-family: 'SCDream3';
  src: local('SCDream3'), url(${SCDream3}) format('opentype');
}
@font-face {
  font-family: 'SCDream4';
  src: local('SCDream4'), url(${SCDream4}) format('opentype');
}
@font-face {
  font-family: 'SCDream5';
  src: local('SCDream5'), url(${SCDream5}) format('opentype');
}
@font-face {
  font-family: 'SCDream6';
  src: local('SCDream6'), url(${SCDream6}) format('opentype');
}
@font-face {
  font-family: 'SCDream7';
  src: local('SCDream7'), url(${SCDream7}) format('opentype');
}
@font-face {
  font-family: 'SCDream8';
  src: local('SCDream8'), url(${SCDream8}) format('opentype');
}
@font-face {
  font-family: 'SCDream9';
  src: local('SCDream9'), url(${SCDream9}) format('opentype');
}

  @font-face {
      font-family: 'NotoSansKR-Thin';
      src: local('NotoSansKR-Thin'), url(${NotoSansKrThin}) format('opentype');
  }
  @font-face {
      font-family: 'NotoSansKR-Light';
      src: local('NotoSansKR-Light'), url(${NotoSansKrLight}) format('opentype');
  }
  @font-face {
      font-family: 'NotoSansKR-Regular';
      src: local('NotoSansKR-Regular'), url(${NotoSansKrRegular}) format('opentype');
  }
  @font-face {
      font-family: 'NotoSansKR-Medium';
      src: local('NotoSansKR-Medium'), url(${NotoSansKrMedium}) format('opentype');
  }
  @font-face {
    font-family: 'NotoSansKR-Bold';
    src: local('NotoSansKR-Bold'), url(${NotoSansKrBold}) format('opentype');
  }
  @font-face {
    font-family: 'NotoSansKR-Black';
    src: local('NotoSansKR-Black'), url(${NotoSansKrBlack}) format('opentype');
  }

  * {
    margin: 0;
    font-family: 'SCDream4', sans-serif;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* font-weight: bold; */
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  html {
    font-size: 10px;
  }
  body {
    height: 100%;
  }
  a, button {
    text-decoration: none;
    cursor: pointer;
    border: none;
    outline: none;
  }

  h1 {
    ${theme('fontStyle.h3')}
  }

  h2 {
    ${theme('fontStyle.h4')}
  }

  h2 {
    ${theme('fontStyle.h5')}
  }

  h3 {
    ${theme('fontStyle.subtitle1')}
  }

  h4 {
    ${theme('fontStyle.subtitle2')}
  }

  h5 {
    ${theme('fontStyle.body1')}
  }

  text{
    font-family: 'NotoSansKR-Bold';
  }

  p {
    ${theme('fontStyle.body2')}
  }
  .hljs{
    background: #000000;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  /* ::-webkit-scrollbar {
    display: none;
  } */
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
    height: 4px;
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    max-width: 230px;
    background-color: #5d7160;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(180, 185, 171, 0.3);
    background-color: #b4b9ab;
  }
  outline : none;

  .slick-slider {
    .slick-arrow {
      &.slick-prev{
        display: none !important;
      }
      &.slick-next{
        display: none !important;
      }
    }
  }

  .ant-btn[disabled], .ant-btn[disabled]:hover{
    border: none;
    font-family: 'NotoSansKR-Medium';
  }
  
  /* Select */
  .ant-select-item-option-content {
    font-size: 12px;
    font-family: 'NotoSansKR-regular';
  }

  /* CheckBox */
  .ant-checkbox-wrapper{
    .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
      border: 1px solid #d9d9d9;
    }
    .ant-checkbox-checked{
      &::after{
        border: none;
      }
    }
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #5c7161;
    &::after {
      background: #5c7161;
    }
  }
  .ant-radio-wrapper {
    margin-right: 40px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #5c7161;
    border-color: #5c7161;

    /* &:after{
      content: "\e816" ;
      font-family: 'themify-icons';
      border:none;
      transform: rotate(0deg) scale(1) translate(-20%, -20%);
    } */
  }

  /** ======================================== */

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .ant-modal-wrap {
    cursor: url(${Close}), auto;
  }
  .ant-modal-content {
    cursor: default;
  }

  .hidden-mobile {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  /* .ant-pagination {
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }; */
  .ant-pagination-options {
    display: none !important;
  }
  .ant-pagination {
    .ant-pagination-item {
      /* height: 32px; */
      margin: 0;
      border: none;
      background: transparent;
      a {
        font-family: "SCDream1";
        height: 32px;
        font-size: 12px;
        padding: 0;
        background: transparent;
        color: #337ab7;
      }
    }
    .ant-pagination-item-active {
      a {
        color: #ffffff;
        background: #242a30;
      }
    }
    /* .ant-pagination-item-link {
      border: none;
      background: transparent;
    } */

    @media screen and (max-width: 768px) {
      justify-content: center;
      text-align: center;
    }
  }
  /**modal */
  .ant-modal-root{
    .ant-modal {
      @media(max-width : 360px) {
        /* margin: 0; */
        max-width: 100% !important;
        padding: 0 15px;
      }
    }
  }
  /**table */
  .ant-table-wrapper {
    .ant-table-body {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0px;
        display: block;
      }
    }
    .ant-checkbox-wrapper-checked {
      :hover  {
        .ant-checkbox-inner {
          background-color: #5d7160 !important;
        }
      }
    }
    .ant-checkbox-indeterminate {
      .ant-checkbox-inner {
        :after {
          background-color: transparent !important;
        }
      }
    }
  }
  .ant-table-wrapper .ant-table-body .ant-table-tbody > tr > td {
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    &:last-child {
      border-right: none;
    }
  }

  /**select antd */
  .ant-select-dropdown {
    border-radius: 0 !important;
  }
  .ant-select-item {
    /* padding: 4px !important; */
    min-height: 20px !important;
    padding: 0 2px 0 14px !important;
    .ant-select-item-option-content {
      font-family: "NotosansKr-Regular";
      font-size: 12px;
      font-weight: 500;
      color: #000;
    }
    .ant-select-selector {
      font-family: "NotosansKr-Bold";
    }
    &.ant-select-item-option-selected {
      border-radius: 0 !important;
      /* padding-left: 14px !important; */
      &::before {
          content: "✔";
          position: absolute;
          left: 2px;
          top: 4px;
          font-size: 10px;
        }
    }
  }
  .ant-picker-dropdown {
    .ant-picker-panel-container {
      zoom: 0.7;
    }
  }

  .marquee {
    min-width: unset !important;
  }

  /**popup error api */
  .ant-notification {
    .ant-notification-notice {
      .ant-notification-notice-message {
        text-align: center;
        margin-top: 8px;
        text-align: center;
        font-family: "Montserrat-Medium";
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.6px;
        color: #5c7161;
        padding-right: 40px;
      }
      .ant-notification-notice-close {
        top : 24px;
        .ant-notification-close-icon {
          font-size: 13px;
          color: #111;
          border-radius: 50%;
          background-color: #d9d9d9;
          padding: 5px;
        }
      }
    }
  }

  /** daum kakao address */
  .box-modified{
    >div >span{
      background-color: #5d7160;
    }
  }
`

export default GlobalStyles
