import { languageList } from 'common/constants/languageConfig';

export const getLangParams = ()=>{
  const langList = ['ko', 'vi']
  const url = window.location
  const splitUrl =  url.pathname.split('/').filter(Boolean)[0]
  const foundUrl = langList.find((e)=> e.includes(splitUrl))

  return foundUrl
}

export const isEmptyObject = (obj: object) =>{
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
    }
    return true;
}

export const getBase64 = (file: Blob, callBack: (url: string) => void) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      callBack(reader.result as string)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
}


export const changeParamWithLocale = (lang:any) => {
  const currentPath = window.location.pathname
  const query = window.location.search
  let url = ''
  const path:any = languageList.find((value:any)=>{
    const find = value?.lang === lang
    if (find) {
      return value
    }
  })
  const paramsUrl = currentPath.split('/')
  const languagePath = paramsUrl[0]
  if ( lang !== 'ko') {    
    if(languageList.some((item:any)=>{return item.lang === languagePath})){
      url = currentPath === '/' ? `/${path.lang}` : `/${path.lang}`;
    }
    else{
      url = currentPath === '/' ? `/${path.lang}` : `/${path.lang}${currentPath.replace(/\/(ko|vi|en|jp)/g, '')}`;
    }
  }
  else {
    url = currentPath.replace(/\/(ko|vi|en|jp)/g, '')
  }

  window.history.replaceState({}, '', url + query || '/')
}