const getLangParams = () => {
  const langList = ["en", "vi", 'jp'];
  const url = window.location;
  const splitUrl = url.pathname.split("/").filter(Boolean)[0];
  const foundUrl = langList.find((e) => e.includes(splitUrl));

  return foundUrl;
};

const basePath =
  getLangParams() === "ko"
    ? "/"
    : `/${getLangParams() || ""}${getLangParams() ? "/" : ""}`;
export const UrlInternal ={
  // Name Page
  HOME : `${basePath}`,
  MAINTAIN:`${basePath}site-maintain`,
  LOGIN : `${basePath}login`,
  REGISTER : `${basePath}register`,
  USER : `${basePath}user`,
  PAYMENT : `${basePath}payment`,
  DEPOSIT : `${basePath}deposit`,
  POINT : `${basePath}point`,
  NOTIFICATION : `${basePath}notification`,
  NOTIFICATION_DETAIL : `${basePath}notification/detail/:id`,
  NOTIFICATION_DETAIL_ROUTER : `${basePath}notification/detail/`,
  EVENT : `${basePath}event`,
  EVENT_DETAIL : `${basePath}event/detail/:id`,
  EVENT_DETAIL_ROUTER : `${basePath}event/detail/`,
  CUSTOMER_CENTER : `${basePath}customer-center`,
  CUSTOMER_CENTER_REGISTER : `${basePath}customer-center/register`,
  CUSTOMER_CENTER_DETAIL : `${basePath}customer-center/detail/:id`,
  CUSTOMER_CENTER_DETAIL_ROUTER : `${basePath}customer-center/detail/`,
  BET : `${basePath}bet`,
  MESSAGE : `${basePath}messages`,
  CASINO : `${basePath}live`,
  CASINO_DETAIL : `${basePath}live/:id`,
  CASINO_DETAIL_ROUTER : `${basePath}live/`,
  SLOT_GAME : `${basePath}rng`,
  SLOT_DETAIL : `${basePath}rng/:id`,
  SLOT_DETAIL_ROUTER : `${basePath}rng/`,
  MINI_GAME : `${basePath}mini_lobby`,
  MINI_GAME_DETAIL : `${basePath}mini_lobby/detail/:id`,
  MINI_GAME_DETAIL_ROUTER : `${basePath}mini_lobby/detail/`,
  SPORT : `${basePath}sports`,
  SPORTS_DETAIL : `${basePath}sports/detail/:id`,
  SPORTS_DETAIL_ROUTER : `${basePath}sports/detail/`,
  SPORT_SOLO_ROUTER : `${basePath}sports/detail/0`,
  SPORT_SPECIAL_ROUTER : `${basePath}sports/detail/3`,
  SPORT_LIVE_ROUTER : `${basePath}sports/detail/r`,

  // partner
  PARTNER : `${basePath}partner`,
  PARTNER_ROUTER : `${basePath}partner/`,
  PARTNER_RATE_DETAIL : `${basePath}partner/rate/:id`,
  PARTNER_RATE_DETAIL_ROUTER : `${basePath}partner/rate/`,
  PARTNER_CHILD_DETAIL : `${basePath}partner/child/:id`,
  PARTNER_CHILD_DETAIL_ROUTER : `${basePath}partner/child/`,
  PARTNER_ADD_CHILD_DETAIL : `${basePath}partner/add_child/:id`,
  PARTNER_ADD_CHILD_DETAIL_ROUTER : `${basePath}partner/add_child/`,
  PARTNER_DEPOSIT_LIST : `${basePath}partner/deposit_list/:id`,
  PARTNER_WITHDRAW_LIST : `${basePath}partner/withdraw_list/:id`,
  PARTNER_BETTING_LIST : `${basePath}partner/betting_list/:id`,
  PARTNER_ROLLING_LIST : `${basePath}partner/rolling_list/:id`,
  PARTNER_PARTNER_DEPOSIT_LIST : `${basePath}partner/partner_deposit_list/:id`,
  PARTNER_PARTNER_WITHDRAW_LIST : `${basePath}partner/partner_withdraw_list/:id`,
  PARTNER_PARTNER_DEPOSIT_LIST_ALL : `${basePath}partner/partner_deposit_list_all/:id`,
  PARTNER_PARTNER_WITHDRAW_LIST_ALL : `${basePath}partner/partner_withdraw_list_all/:id`,
  PARTNER_CHILD_LIST : `${basePath}partner/child_list/:id`,
  PARTNER_DEPOSIT_RANKING : `${basePath}partner/deposit_ranking/:id`,
  PARTNER_WITHDRAW_RANKING : `${basePath}partner/withdraw_ranking/:id`,
  PARTNER_BETTING_RANKING : `${basePath}partner/betting_ranking/:id`,
  PARTNER_BETTING_RANKING_DETAIL : `${basePath}partner/betting_ranking/detail/:id`,
  PARTNER_BETTING_RANKING_DETAIL_ROUTER : `${basePath}partner/betting_ranking/detail/`,
  PARTNER_ROLLING_RANKING : `${basePath}partner/rolling_ranking/:id`,
  PARTNER_DEPOSIT_MONEY : `${basePath}partner/deposit_money/:id`,
  PARTNER_WITHDRAW_MONEY : `${basePath}partner/withdraw_money/:id`,
  PARTNER_DEPOSIT_MONEY_ROUTER : `${basePath}partner/deposit_money/`,
  PARTNER_WITHDRAW_MONEY_ROUTER : `${basePath}partner/withdraw_money/`,

  // HISTORY_BETTING : "/history-betting",
  HISTORY_BETTING_POWER : `${basePath}history-betting-power`,
  HISTORY_BETTING_SOLO : `${basePath}history-betting-solo`,
  HISTORY_BETTING_SPECIAL : `${basePath}history-betting-special`,
  HISTORY_BETTING_LIVE : `${basePath}history-betting-live`,

  // HISTORY_SPORT : "/history-sport",
  HISTORY_SPORT_SOLO : `${basePath}history-sport-solo`,
  HISTORY_SPORT_SPECIAL : `${basePath}history-sport-special`,
  HISTORY_SPORT_LIVE : `${basePath}history-sport-live`,

  BLANK : `${basePath}blank/:id`,
  BLANK_ROUTER : `${basePath}blank/`
}
