import { mainAxios } from "libs/axios";

export const casinoListApi = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/casino/as`,
    // params: payload,
    requiresToken: false,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};
export const getType = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/site/config`,
    // params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};
export const getListCasino = (type: string) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/${type}/casino`,
    // params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

interface CasinoTypeProps {
  type: string;
  code: string | number;
  typeGame: string;
}

export const getCasinoType = (payload: CasinoTypeProps, op = "as") => {
  return mainAxios.request({
    methodType: "GET",
    url: `/${payload.typeGame}/casino/gamelist?code=${payload.code}&type=${payload.typeGame}`,
    // params: payload,
    requiresToken: true,
    notError: true,
    getError: false,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

interface CasinoDetailProps {
  vendor: string;
  game_id: string | number;
}

export const casinoGameDetailApi = (payload: CasinoDetailProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/casino/as/launch`,
    payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

interface LaunchProps {
  vendor: string;
  type: string;
  game_code: string;
  typeGame: string;
}

export const getLaunchCasino = (payload: LaunchProps, op = "as") => {
  return mainAxios.request({
    methodType: "POST",
    url: `/${payload.typeGame}/launch`,
    payload: {
      vendor: payload.vendor,
      type: payload.type,
      game_code: payload.game_code,
    },
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};
