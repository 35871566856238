import React, { useState, useEffect } from 'react' //lazy
import { useHistory } from 'react-router-dom'
import moneyConvert from 'utils/moneyConvert'
import { useDispatch, useSelector } from 'react-redux'

import { Layout } from 'antd'
import { UrlInternal } from 'common/constants/endpoints'
// import { ArrowUpOutlined } from "@ant-design/icons";
import types from 'store/actions/types'

import * as S from './style'

const { Footer } = Layout

interface Props {
  onClick?: any
  className?: any
}

const BoxInfo = (props: Props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { onClick, className } = props
  const dataMe = useSelector((state: any) => state.user)

  const Logout = () => {
    localStorage.removeItem('User')
    localStorage.removeItem('Popup')
    history.push(UrlInternal.LOGIN)
  }

  return (
    <S.Wrapper>
      <S.BoxInfo>
        <div
          className='item label'
          onClick={() => history.push(UrlInternal.USER)}
        >
          {dataMe.dataUser?.mb_name}
        </div>
        <div className='item infos'>
          <em className='money'>
            <em className='icicon text-gray'></em>
          </em>
          <span className='info'>
            보유머니
            <span className='value'>
              {moneyConvert(dataMe.dataUser?.mb_money, true)}
            </span>
            원
          </span>
        </div>
        <div
          className='item infos'
          onClick={() => history.push(UrlInternal.POINT)}
        >
          <em className='point'>
            <em className='fa icicon text-gray'></em>
          </em>
          <span className='info'>
            포인트
            <span className='value'>
              {moneyConvert(dataMe.dataUser?.mb_point, true)}
            </span>
            점<i className='fa fa-recycle text-warning'></i>전환
          </span>
        </div>
      </S.BoxInfo>
      <S.ButtonBox>
        <button
          className='bt pri'
          onClick={() => history.push(UrlInternal.CUSTOMER_CENTER)}
        >
          <i className='fa fa-solid fa-comment-dots'></i> 문의
        </button>
        <button
          className='bt pri'
          onClick={() => history.push(UrlInternal.MESSAGE)}
        >
          <i className='fa fa-envelope text-success'></i> 쪽지
        </button>
        <button
          className='bt pri'
          onClick={() => history.push(UrlInternal.DEPOSIT)}
        >
          <i className='fa fa-plus-square text-primary'></i> 입금
        </button>
        <button
          className='bt pri'
          onClick={() => history.push(UrlInternal.PAYMENT)}
        >
          <i className='fa fa-minus-square text-danger'></i> 출금
        </button>
        <button className='bt out' onClick={Logout}>
          <i className='fa fa-power-off text-danger f-s-13 m-l-4 m-r-4'></i>
        </button>
      </S.ButtonBox>
    </S.Wrapper>
  )
}

export default BoxInfo
